<template>
  <div class="main">
    <div class="bootstrap panel">
      <b-table
        :sort-by="'transactionDate'"
        :sticky-header="stickyTableHeight"
        selectable
        :show-empty="true"
        select-mode="single"
        responsive="sm"
        hover
        :fields="tableHeaders"
        head-variant="light"
        sort-icon-left
        @row-clicked="item => pullTransaction(item.transactionID)"
        :items="returnedData"
      >
        <template v-slot:cell(transactionType)="data">
          {{
            transactionTypes[data.item.transactionTypeCode] ||
            data.item.transactionTypeCode
          }}
        </template>
        <template v-slot:cell(transactionTimestamp)="data">
          {{
            data.item.transactionDate
              .replace("T", " ")
              .replace(/-\d{2}:\d{2}/g, "")
          }}
        </template>
        <template v-slot:cell(error)="data">
          <template v-if="data.item.err != undefined">
            {{ data.item.err.err }}
          </template>
          <template v-else> </template>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import vehicleFunctions from "@/mixins/vehicleFunctions.js";
const transactionTypes = require("@/assets/jsonScaffolds/transactionTypes.json");
export default {
  mixins: [vehicleFunctions],
  data() {
    return {
      invoice: "",
      returnedData: [],
      transactionTypes: transactionTypes,
      stickyTableHeight: "calc(100vh - 200px)",
      tableHeaders: [
        {
          key: "vin",
          sortable: true
        },
        {
          key: "plateNo",
          label: "Plate",
          sortable: true
        },
        {
          key: "customerName",
          sortable: true
        },
        {
          key: "transactionType",
          sortable: true
        },
        {
          key: "transactionTimestamp",
          sortable: true,
          sortDesc: false
        },
        {
          key: "error",
          sortable: false
        }
      ]
    };
  },
  methods: {
    async pullTransaction(id) {
      this.$store.commit("fromDeficientList", true);
      this.$root.$emit("setLoading", true);
      try {
        const json = await this.$api.getDeficient(
          id,
          this.$store.getters.countyId
        );
        const processObj = this.reformatTandrObjectforTransactionScreen(
          JSON.parse(json.transactionJSON)
        );
        processObj.transaction.isDeficientFinish = true;
        this.$store.commit("processObj", processObj);
        this.$store.commit(
          "transactionType",
          transactionTypes[processObj.transaction.transactionType]
        );
        this.$router.push({
          name: "Transaction",
          params: {
            vin: processObj.vehicle.vin || "none",
            make: processObj.vehicle.makeCode || "none",
            year: processObj.vehicle.modelYear || "none",
            menuIsShown: true
          }
        });
      } finally {
        this.$root.$emit("setLoading", false);
      }
    }
  },
  async mounted() {
    this.$root.$emit("setLoading", true);
    try {
      this.returnedData = await this.$api.getCountyDeficients(
        this.$store.getters.countyId
      );
    } finally {
      this.$root.$emit("setLoading", false);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .b-table tbody tr:focus {
  background-color: var(--borders-backgrounds);
}
.main {
  padding: 25px;
}

.topForm div {
  margin-right: 10px;
  float: left;
}
#resultsTable tbody tr {
  cursor: pointer;
}
#resultsTable tbody tr:hover {
  background-color: lightgray;
}
</style>
